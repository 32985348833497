import { Dispatch, FC } from 'react';
import { logout } from '../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { Layout } from 'antd';
import { ReactComponent as ManekiLogo } from '../moneycat.svg';
import { Button } from 'antd';
import styled from 'styled-components';
const { Header } = Layout;

const LogoutButton = styled.div`
  button {
    height: 100%;
    background-color: #177797;
    border: transparent;
    &:hover {
      background-color: #177797;
    }
    font-family: 'Raleway', sans-serif !important;
    color: #fff !important;
  }
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const NavHeader = styled(Header)`
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ul {
    width: 100%;
  }
  height: 4rem;
  background-color: #177797 !important;
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

const LogoDiv = styled.div`
  height: 3rem;
  margin: 1rem;
  padding: 0 1rem;
`;

const Logo = styled(ManekiLogo)`
  height: 3rem;
  fill: white;
`;

const Menu = styled.div`
  display: flex;
  justify-content: flex-start;
  font-family: 'Raleway', sans-serif;
  width: 80%;
`;

const MenuItem = styled.div`
  display: flex;
  width: 24rem;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => (props.color === '#cee0ea' ? '#003b4d' : '#ffffff')};
  justify-content: center;
  &:hover {
    background-color: #cee0ea;
    color: #003b4d;
  }
  span {
    text-align: center;
    padding: 0rem 1rem;
  }

  background-color: ${(props) => props.color || '#40344e'};
`;

export interface NavProps {
  loggedIn: boolean;
  page: string;
  setPage: Dispatch<string>;
  onSignOut: () => void;
}

const Nav: FC<NavProps> = ({ loggedIn, page, setPage, onSignOut }) => {
  const dispatch = useDispatch();
  const handleClick = (value: string) => () => {
    setPage(value);
  };

  const getColor = (value: string) => {
    if (page === value) {
      return '#cee0ea';
    }
    return '#177797';
  };

  const handleSignOut = () => {
    onSignOut();
    dispatch(logout());
  };

  return (
    <NavHeader>
      <LogoDiv onClick={handleClick('landing')}>
        <Logo />
      </LogoDiv>
      {loggedIn ? (
        <Menu>
          <MenuItem
            onClick={handleClick('watchlist')}
            color={getColor('watchlist')}
          >
            <span>
              <b>Watchlist</b>
            </span>
          </MenuItem>
          <MenuItem
            onClick={handleClick('daytrade')}
            color={getColor('daytrade')}
          >
            <span>
              <b>Casino</b>
            </span>
          </MenuItem>
          <MenuItem onClick={handleClick('recs')} color={getColor('recs')}>
            <span>
              <b>Recommendations</b>
            </span>
          </MenuItem>
          <MenuItem onClick={handleClick('trades')} color={getColor('trades')}>
            <span>
              <b>Trade Journal</b>
            </span>
          </MenuItem>
          <MenuItem
            onClick={handleClick('diagnostics')}
            color={getColor('diagnostics')}
          >
            <span>
              <b>Diagnostics</b>
            </span>
          </MenuItem>
          <LogoutButton>
            <Button type='primary' ghost onClick={handleSignOut}>
              Logout
            </Button>
          </LogoutButton>
        </Menu>
      ) : null}
    </NavHeader>
  );
};

export default Nav;
