import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchUser, AuthParams } from './authAPI';

export interface AuthState {
  loggedIn: boolean;
  message: string;
  status: string;
  userId: string;
  emailAddress: string;
  token: string;
}

const initialState: AuthState = {
  loggedIn: false,
  message: '',
  status: 'pending',
  userId: '',
  emailAddress: '',
  token: '',
};

export const fetchUserByEmail = createAsyncThunk(
  'auth/user',
  async (args: AuthParams) => {
    const response = await fetchUser(args);
    return response;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.userId = '';
      state.emailAddress = '';
      state.loggedIn = false;
      state.message = '';
      state.status = 'pending';
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserByEmail.fulfilled, (state, action) => {
        state.loggedIn = true;
        state.message = action.payload.message;
        state.status = 'success';
        state.userId = action.payload.data.id;
        state.emailAddress = action.payload.data.emailAddress;
      })
      .addCase(fetchUserByEmail.pending, (state) => {
        state.loggedIn = false;
        state.message = '';
        state.status = 'pending';
        state.userId = '';
        state.emailAddress = '';
      })
      .addCase(fetchUserByEmail.rejected, (state) => {
        state.loggedIn = false;
        state.message = 'Not Authorized';
        state.status = 'rejected';
        state.userId = '';
        state.emailAddress = '';
      });
  },
});

export const { logout, setToken } = authSlice.actions;
export default authSlice.reducer;
