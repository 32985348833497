import { client } from '../../utils/fetch';

export interface PlotDataParams {
  token: string;
  ticker: string;
}

export const fetchPlotData = (args: PlotDataParams) => {
  const headers = {
    authorization: args.token,
  };
  return client.get(`/api/v0/daytrade/${args.ticker}`, {
    headers: headers,
  });
};
