import { FC } from 'react';
import { RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchCandlesByTicker,
  fetchMACDByTicker,
  fetchStockModelByTicker,
  setTicker,
} from '../../features/stocks/stocksSlice';
import { MACDParams } from '../../features/stocks/stocksAPI';
import StockSelectorDesktop from './StockSelectorDesktop';
import StockSelectorMobile from './StockSelectorMobile';

interface SSProps {
  token: string;
}

const StockSelector: FC<SSProps> = ({ token }) => {
  const dispatch = useDispatch();
  const period = useSelector((state: RootState) => state.tickers.period);
  const modelType = useSelector((state: RootState) => state.tickers.sortedBy);
  const macdModel = useSelector((state: RootState) => state.stocks.modelType);

  const handleTickerCardClick = (ticker: string) => {
    let args = {
      token: token,
      period: period,
      ticker: ticker,
    };
    dispatch(fetchCandlesByTicker(args));
    let macdArgs = { ...args } as MACDParams;
    if (period === 'hourly') {
      macdArgs.cadence = macdModel;
    }
    dispatch(fetchMACDByTicker(macdArgs));
    let modelArgs = {
      modelType: 'proba_up',
      ...args,
    };
    dispatch(fetchStockModelByTicker(modelArgs));
    dispatch(setTicker(ticker));
  };

  return (
    <>
      <StockSelectorDesktop token={token} onCardClick={handleTickerCardClick} />
      <StockSelectorMobile token={token} onCardClick={handleTickerCardClick} />
    </>
  );
};

export default StockSelector;
