import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';

import PlotlyChart, { ChartContainer } from '../PlotlyChart';

const ModelChart = () => {
  const data = useSelector((state: RootState) => state.stocks.models);
  const period = useSelector((state: RootState) => state.tickers.period);
  if (period === '15m') {
    return null;
  }
  return (
    <ChartContainer height='600px'>
      <PlotlyChart {...data} />
    </ChartContainer>
  );
};

export default ModelChart;
