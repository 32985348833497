import { FC, useEffect, useState } from 'react';
import { Layout, Col, Row, Select, Typography } from 'antd';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import ReturnsChart from './diagnostics/ReturnsChart';
import DrawdownChart from './diagnostics/DrawdownChart';
import ReturnsHeatmap from './diagnostics/ReturnsHeatmap';
import DrawdownHeatmap from './diagnostics/DrawdownHeatmap';
import Signals from './diagnostics/Signals';
import {
  fetchModelDiagnostics,
  fetchModelHeatmaps,
  fetchSignalsByDay,
} from '../features/stocks/stocksSlice';
const { Title } = Typography;
const { Option } = Select;

const DAILY_OPTIONS = [
  { value: 'proba_up', label: 'P(7% Gain in 5 days)' },
  { value: 'proba_bear', label: 'P(Bearish Cross in 5 days)' },
  { value: 'proba_bull', label: 'P(Bullish Cross in 5 days)' },
];
const HOURLY_OPTIONS = [
  { value: 'proba_up', label: 'P(3% Gain in 10 Hrs)' },
  { value: 'slow_bear', label: 'P(Bearish Cross in 5 Hrs)' },
  { value: 'slow_bull', label: 'P(Bullish Cross in 5 Hrs)' },
];
const Container = styled.div`
  padding: 1rem 1rem;
  margin: 0 3rem;
  background-color: #e7e7e7;
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

interface DiagnosticsParams {
  token: string;
}
const Diagnostics: FC<DiagnosticsParams> = ({ token }) => {
  const dispatch = useDispatch();
  const [modelType, setModelType] = useState('proba_up');
  const [compareModel, setCompareModel] = useState('proba_bull');
  const [period, setPeriod] = useState('daily');

  useEffect(() => {
    let args = {
      token: token,
      period: 'daily',
      modelType: 'proba_up',
    };
    dispatch(fetchModelDiagnostics(args));

    let heatArgs = {
      token: token,
      period: 'daily',
      xModel: 'proba_up',
      yModel: 'proba_bull',
    };
    dispatch(fetchModelHeatmaps(heatArgs));

    let signalArgs = {
      token: token,
    };
    dispatch(fetchSignalsByDay(signalArgs));
  }, []);

  const handleModelSelect = (value: string) => {
    let args = {
      token: token,
      period: period,
      modelType: value,
    };
    dispatch(fetchModelDiagnostics(args));
    setModelType(value);
    let heatArgs = {
      token: token,
      period: period,
      xModel: value,
      yModel: compareModel,
    };
    dispatch(fetchModelHeatmaps(heatArgs));
  };

  const handlePeriodSelect = (value: string) => {
    let args = {
      token: token,
      period: value,
      modelType: modelType,
    };
    dispatch(fetchModelDiagnostics(args));
    setPeriod(value);

    let heatArgs = {
      token: token,
      period: value,
      xModel: modelType,
      yModel: compareModel,
    };
    dispatch(fetchModelHeatmaps(heatArgs));
  };

  const handleCompareSelect = (value: string) => {
    let args = {
      token: token,
      period: period,
      xModel: modelType,
      yModel: value,
    };
    dispatch(fetchModelHeatmaps(args));
    setCompareModel(value);
  };

  return (
    <Container>
      <Row gutter={8}>
        <Col span={8}>
          <Title level={5}>Period</Title>
          <Select
            value={period}
            style={{ width: '12rem' }}
            onChange={handlePeriodSelect}
          >
            <Option key={`key_daily`} value={'daily'}>
              Daily
            </Option>
            <Option key={`key_hourly`} value={'hourly'}>
              Hourly
            </Option>
          </Select>
        </Col>
        <Col span={8}>
          <Title level={5}>Model</Title>
          <Select
            value={modelType}
            style={{ width: '12rem' }}
            onChange={handleModelSelect}
          >
            {(period === 'daily' ? DAILY_OPTIONS : HOURLY_OPTIONS).map(
              (option) => (
                <Option key={`key_${option.value}`} value={option.value}>
                  {option.label}
                </Option>
              )
            )}
          </Select>
        </Col>
        <Col span={8}>
          <Title level={5}>Compare With</Title>
          <Select
            value={compareModel}
            style={{ width: '12rem' }}
            onChange={handleCompareSelect}
          >
            {(period === 'daily' ? DAILY_OPTIONS : HOURLY_OPTIONS).map(
              (option) => (
                <Option key={`key_comp_${option.value}`} value={option.value}>
                  {option.label}
                </Option>
              )
            )}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Signals />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <ReturnsChart />
        </Col>
        <Col span={12}>
          <DrawdownChart />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <ReturnsHeatmap />
        </Col>
        <Col span={12}>
          <DrawdownHeatmap />
        </Col>
      </Row>
    </Container>
  );
};

export default Diagnostics;
