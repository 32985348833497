import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CandlesParams,
  ModelParams,
  MACDParams,
  DiagnosticParams,
  HeatmapParams,
  SignalParams,
  fetchSignals,
  fetchStockCandles,
  fetchStockMACD,
  fetchStockModel,
  fetchDiagnostics,
  fetchHeatmaps,
} from './stocksAPI';
import { PlotParams } from 'react-plotly.js';

export interface StocksState {
  candles: PlotParams;
  macd: PlotParams;
  models: PlotParams;
  modelType: string;
  returns: PlotParams;
  drawdown: PlotParams;
  heatmapReturns: PlotParams;
  heatmapDrawdown: PlotParams;
  signals: PlotParams;
  ticker: string;
}

const initialState: StocksState = {
  candles: {
    data: [],
    layout: {},
  },
  macd: {
    data: [],
    layout: {},
  },
  models: {
    data: [],
    layout: {},
  },
  returns: {
    data: [],
    layout: {},
  },
  drawdown: {
    data: [],
    layout: {},
  },
  heatmapReturns: {
    data: [],
    layout: {},
  },
  heatmapDrawdown: {
    data: [],
    layout: {},
  },
  signals: {
    data: [],
    layout: {},
  },
  modelType: 'slow',
  ticker: 'SPXL',
};

export const fetchCandlesByTicker = createAsyncThunk(
  'stocks/candles',
  async (args: CandlesParams) => {
    const response = await fetchStockCandles(args);
    return response;
  }
);

export const fetchMACDByTicker = createAsyncThunk(
  'stocks/macd',
  async (args: MACDParams) => {
    const response = await fetchStockMACD(args);
    return response;
  }
);

export const fetchStockModelByTicker = createAsyncThunk(
  'stocks/model',
  async (args: ModelParams) => {
    const response = await fetchStockModel(args);
    return response;
  }
);

export const fetchModelDiagnostics = createAsyncThunk(
  'stocks/diagnostics',
  async (args: DiagnosticParams) => {
    const response = await fetchDiagnostics(args);
    return response;
  }
);

export const fetchModelHeatmaps = createAsyncThunk(
  'stocks/heatmaps',
  async (args: HeatmapParams) => {
    const response = await fetchHeatmaps(args);
    return response;
  }
);

export const fetchSignalsByDay = createAsyncThunk(
  'stocks/signals',
  async (args: SignalParams) => {
    const response = await fetchSignals(args);
    return response;
  }
);
export const stocksSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {
    setModelType: (state, action: PayloadAction<string>) => {
      state.modelType = action.payload;
    },
    setTicker: (state, action: PayloadAction<string>) => {
      state.ticker = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandlesByTicker.fulfilled, (state, action) => {
        state.candles = action.payload as PlotParams;
      })
      .addCase(fetchMACDByTicker.fulfilled, (state, action) => {
        state.macd = action.payload as PlotParams;
      })
      .addCase(fetchStockModelByTicker.fulfilled, (state, action) => {
        state.models = action.payload as PlotParams;
      })
      .addCase(fetchModelDiagnostics.fulfilled, (state, action) => {
        state.returns = action.payload.returns as PlotParams;
        state.drawdown = action.payload.drawdown as PlotParams;
      })
      .addCase(fetchModelHeatmaps.fulfilled, (state, action) => {
        state.heatmapReturns = action.payload.returns as PlotParams;
        state.heatmapDrawdown = action.payload.drawdown as PlotParams;
      })
      .addCase(fetchSignalsByDay.fulfilled, (state, action) => {
        state.signals = action.payload as PlotParams;
      });
  },
});

export const { setModelType, setTicker } = stocksSlice.actions;
export default stocksSlice.reducer;
