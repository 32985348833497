import { FC } from 'react';
import { Row, Col } from 'antd';
import CandleChart from './recommendations/CandleChart';
import ModelChart from './recommendations/ModelChart';
import MACDChart from './recommendations/MACDChart';
import PeriodSelect from './recommendations/PeriodSelect';
import ModelSelect from './recommendations/ModelSelect';
import TickerSelect from './recommendations/TickerSelect';
import StockSelector from './recommendations/StockSelector';
import styled from 'styled-components';
import { Layout } from 'antd';
const { Content, Sider } = Layout;

const StyledRow = styled(Row)`
  padding: 1rem 1rem;
  margin: 1rem 1rem;
`;

const StyledContent = styled(Content)`
  padding: 1rem 1rem;
  margin: 0rem 0rem;
  overflow: scroll;
  height: 85vh;
  width: 100%;
  padding: 0;
`;

const Container = styled.div`
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;
interface RecProps {
  token: string;
}

const RecommendationsDesktop: FC<RecProps> = ({ token }) => {
  return (
    <Container>
      <Layout>
        <Sider width={280} style={{ backgroundColor: '#a2927f' }}>
          <StockSelector token={token} />
        </Sider>
        <StyledContent>
          <StyledRow gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <PeriodSelect token={token} />
            </Col>
            <Col span={8}>
              <ModelSelect token={token} />
            </Col>
            <Col span={8}>
              <TickerSelect token={token} />
            </Col>
          </StyledRow>

          <CandleChart />
          <ModelChart />
          <MACDChart />
        </StyledContent>
      </Layout>
    </Container>
  );
};

export default RecommendationsDesktop;
