import { FC } from 'react';
import { Card, Tag, Collapse } from 'antd';
import styled from 'styled-components';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export interface TickerCardProps {
  close: string;
  avg_true_range: string;
  is_buy: boolean;
  is_sell: boolean;
  willr_cross: boolean;
  model_score: string;
  width: number | string;
  ticker: string;
  handleClick: () => void;
}

const CollapseContainer = styled(Collapse)`
  display: none;
  @media only screen and (max-width: 500px) {
    display: flex;
    width: 100%;
  }
`;

const TickerCard: FC<TickerCardProps> = ({
  close,
  avg_true_range,
  model_score,
  width,
  ticker,
  is_buy,
  is_sell,
  willr_cross,
  handleClick,
  children,
}) => {
  const style = {
    background: '#ffffff',
    borderRadius: 0,
  };

  return (
    <Card
      onClick={handleClick}
      style={{ ...style, width: width }}
      title={<h4 style={{ textAlign: 'left' }}>{ticker}</h4>}
      extra={
        <div>
          <Tag color={'green'}>Close: {close}</Tag>
          {is_buy && <Tag color='green'>{willr_cross ? 'BULL+' : 'BULL'}</Tag>}
          {is_sell && <Tag color='red'>BEAR</Tag>}
        </div>
      }
      size='small'
    >
      <div style={{ float: 'right', margin: '2px' }}>
        <Tag
          color='blue'
          style={{ textAlign: 'right' }}
        >{`ATR: ${avg_true_range}`}</Tag>

        <Tag color={'green'} style={{ textAlign: 'right' }}>
          {`Score: ${model_score}`}
        </Tag>
      </div>
      <CollapseContainer
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className='site-collapse-custom-collapse'
      >
        <Panel header='Charts' key={`${ticker}_panel_key`}>
          {children}
        </Panel>
      </CollapseContainer>
    </Card>
  );
};

export default TickerCard;
