import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import stocksReducer from '../features/stocks/stocksSlice';
import tickersReducer from '../features/tickers/tickersSlice';
import tradeReducer from '../features/trades/tradeSlice';
import navReducer from '../features/nav/navSlice';
import authReducer from '../features/auth/authSlice';
import watchlistReducer from '../features/watchlist/watchlistSlice';
import dayTradeReducer from '../features/daytrade/dayTradeSlice';

export const store = configureStore({
  reducer: {
    stocks: stocksReducer,
    tickers: tickersReducer,
    nav: navReducer,
    trades: tradeReducer,
    auth: authReducer,
    watchlist: watchlistReducer,
    daytrade: dayTradeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
