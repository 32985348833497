import { FC, useEffect } from 'react';
import { RootState } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDataByTicker,
  setTicker,
} from '../features/daytrade/dayTradeSlice';
import { Layout, Select, Typography, Row } from 'antd';
import PlotlyChart, { ChartContainer } from './PlotlyChart';
import styled from 'styled-components';
const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;
const OPTIONS = [
  { value: 'SPY', label: 'SPY' },
  { value: 'SPXL', label: 'SPXL' },
  { value: 'SPXS', label: 'SPXS' },
  { value: 'QQQ', label: 'QQQ' },
  { value: 'TQQQ', label: 'TQQQ' },
  { value: 'SQQQ', label: 'SQQQ' },
  { value: 'TNA', label: 'TNA' },
  { value: 'TZA', label: 'TZA' },
  { value: 'SOXL', label: 'SOXL' },
  { value: 'SOXS', label: 'SOXS' },
];
interface RecProps {
  token: string;
}

const StyledContent = styled(Content)`
  padding: 1rem 1rem;
  margin: 0rem 0rem;
  overflow: scroll;
  height: 85vh;
  width: 100%;
  padding: 0;
`;
const StyledRow = styled(Row)`
  padding: 1rem 1rem;
  margin: 1rem 1rem;
`;
const Container = styled.div`
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;
const DayTrade: FC<RecProps> = ({ token }) => {
  const dispatch = useDispatch();
  const ticker = useSelector((state: RootState) => state.daytrade.ticker);
  const data = useSelector((state: RootState) => state.daytrade.data);

  useEffect(() => {
    let plotArgs = {
      token: token,
      ticker: ticker,
    };

    dispatch(fetchDataByTicker(plotArgs));
  }, [dispatch]);

  const handleChange = (value: string) => {
    dispatch(setTicker(value));

    let plotArgs = {
      token: token,
      ticker: value,
    };

    dispatch(fetchDataByTicker(plotArgs));
  };

  return (
    <Container>
      <Layout>
        <StyledContent>
          <StyledRow>
            <Title level={5}>Ticker</Title>
            <Select
              value={ticker}
              style={{ width: '12rem', margin: '0rem 1rem' }}
              onChange={handleChange}
            >
              {OPTIONS.map((option) => (
                <Option key={`key_${option.value}`} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </StyledRow>
          <ChartContainer>
            <PlotlyChart {...data.candles} />
          </ChartContainer>
          <ChartContainer>
            <PlotlyChart {...data.rsi} />
          </ChartContainer>
          <ChartContainer>
            <PlotlyChart {...data.macd} />
          </ChartContainer>
          <ChartContainer>
            <PlotlyChart {...data.vol} />
          </ChartContainer>
        </StyledContent>
      </Layout>
    </Container>
  );
};

export default DayTrade;
