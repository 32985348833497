import { FC } from 'react';
import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';

import PlotlyChart, { ChartContainer } from '../PlotlyChart';

const Signals: FC = () => {
  const data = useSelector((state: RootState) => state.stocks.signals);

  return (
    <ChartContainer height='400px'>
      <PlotlyChart {...data} />
    </ChartContainer>
  );
};

export default Signals;
