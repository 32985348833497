import { FC, useEffect, Dispatch } from 'react';
import { RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { fetchTradesByUserId } from '../../features/trades/tradeSlice';
import { TradeSummary } from '../../features/trades/tradeAPI';

const { Column } = Table;

interface TradeTableProps {
  onClickRow: Dispatch<TradeSummary>;
}
const TradeTable: FC<TradeTableProps> = ({ onClickRow }) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.trades.userTrades);
  const userId = useSelector((state: RootState) => state.auth.userId);
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    let args = {
      state: 'all',
      userId: userId,
      authToken: token,
      page: 1,
    };
    dispatch(fetchTradesByUserId(args));
  }, []);

  return (
    <Table
      dataSource={data}
      onRow={(record) => {
        return { onClick: (event) => onClickRow(record) };
      }}
    >
      <Column
        title='Opened At'
        dataIndex='openedAt'
        key='openedAt'
        render={(value: string) => value?.slice(0, 10)}
      />
      <Column title='Ticker' dataIndex='ticker' key='ticker' />
      <Column
        title='Price'
        dataIndex='openPrice'
        key='openPrice'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column title='Units' dataIndex='units' key='units' />
      <Column
        title='Cost Basis'
        dataIndex='costBasis'
        key='costBasis'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='Closed At'
        dataIndex='closedAt'
        key='closedAt'
        render={(value: string) => value?.slice(0, 10) || 'Open'}
      />
      <Column
        title='Price Sold'
        dataIndex='closePrice'
        key='closePrice'
        render={(value: number) => `$${(value || 0).toFixed(2)}`}
      />
      <Column
        title='Profit/Loss'
        dataIndex='profit'
        key='profit'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='% Gain/Loss'
        dataIndex='profitPercent'
        key='profitPercent'
        render={(value: number) => `${value?.toFixed(2)}%`}
      />
    </Table>
  );
};

export default TradeTable;
