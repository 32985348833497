import { useEffect, useState, FC } from 'react';
import { AuthContext } from '../context/AuthContext';
import { getAuth, User, getIdToken } from 'firebase/auth';
import { initializeFirebase } from '../utils/firebase';
import { useDispatch } from 'react-redux';
import { fetchUserByEmail, setToken } from '../features/auth/authSlice';
initializeFirebase();

const AuthProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<User | null>(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((fbUser) => {
      if (fbUser) {
        setUser(fbUser);
        getIdToken(fbUser, true)
          .then((token) => {
            let body = {
              email_address: fbUser.email || '',
              token: token,
            };
            dispatch(fetchUserByEmail(body));
            dispatch(setToken(token));
          })
          .catch(() => console.log('Authentication Failed!'));
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged((fbUser) => {
      if (fbUser) {
        setUser(fbUser);
        getIdToken(fbUser)
          .then((token) => {
            let body = {
              email_address: fbUser.email || '',
              token: token,
            };
            dispatch(fetchUserByEmail(body));
            dispatch(setToken(token));
          })
          .catch(() => console.log('Authentication Failed!'));
      }
    });
    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
