import { FC } from 'react';
import { logout } from '../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { Layout } from 'antd';
import { ReactComponent as ManekiLogo } from '../moneycat.svg';
import { Menu, Dropdown } from 'antd';
import styled from 'styled-components';
import { NavProps } from './Nav';
const { Header } = Layout;

const NavHeader = styled(Header)`
  padding: 0 1rem;
  display: none;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  ul {
    width: 100%;
  }
  height: 4rem;
  background-color: #177797 !important;
  @media only screen and (max-width: 500px) {
    display: flex;
  }
`;

const LogoDiv = styled.div`
  height: 3rem;
  margin: 1rem;
  padding: 0 1rem;
`;

const Logo = styled(ManekiLogo)`
  height: 3rem;
  fill: white;
`;

const MenuItem = styled(Menu.Item)`
  font-family: 'Raleway', sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 1rem;
  margin: 1rem;
`;

interface MenuOption {
  path: string;
  label: string;
}
const OPTIONS: MenuOption[] = [
  { path: 'watchlist', label: 'Watchlists' },
  { path: 'daytrade', label: 'Casino' },
  { path: 'recs', label: 'Recommendations' },
  { path: 'trades', label: 'Trades' },
  { path: 'diagnostics', label: 'Diagnostics' },
  { path: 'landing', label: 'Home' },
  { path: 'landing', label: 'Logout' },
];

const NavMobile: FC<NavProps> = ({ loggedIn, setPage, onSignOut }) => {
  const dispatch = useDispatch();
  const handleClick = (value: string) => () => {
    setPage(value);
  };

  const handleSignOut = () => {
    onSignOut();
    dispatch(logout());
  };

  const menuItems = () => {
    return OPTIONS.map((item) => {
      if (item.label === 'Logout') {
        return (
          <MenuItem key={`${item.label}_key`} onClick={handleSignOut}>
            <span>
              <b>Logout</b>
            </span>
          </MenuItem>
        );
      }
      return (
        <MenuItem key={`${item.label}_key`} onClick={handleClick(item.path)}>
          <span>
            <b>{item.label}</b>
          </span>
        </MenuItem>
      );
    });
  };
  return (
    <NavHeader>
      {loggedIn ? (
        <Dropdown overlay={<Menu>{menuItems()}</Menu>}>
          <LogoDiv onClick={(e) => e.preventDefault()}>
            <Logo />
          </LogoDiv>
        </Dropdown>
      ) : null}
    </NavHeader>
  );
};

export default NavMobile;
