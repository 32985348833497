import { client } from '../../utils/fetch';

export interface AuthParams {
  token: string;
  email_address: string;
}

export const fetchUser = (body: AuthParams) => {
  return client.post(`/api/v0/auth`, { ...body });
};
