import { client } from '../../utils/fetch';

export interface CandlesParams {
  token: string;
  ticker: string;
  period: string;
  cadence?: string;
}

export interface MACDParams {
  token: string;
  ticker: string;
  period: string;
  cadence?: string;
}

export interface ModelParams {
  token: string;
  period: string;
  ticker: string;
  modelType?: string;
  compareWith?: string;
}

export interface DiagnosticParams {
  token: string;
  period: string;
  modelType: string;
  lookbackDays?: number;
}

export interface HeatmapParams {
  token: string;
  period: string;
  xModel: string;
  yModel: string;
  lookbackDays?: number;
}

export interface SignalParams {
  token: string;
}
export const fetchStockCandles = (args: CandlesParams) => {
  const headers = {
    authorization: args.token,
  };
  return client.get(`/api/v0/${args.period}/candles/${args.ticker}`, {
    headers: headers,
  });
};

export const fetchStockMACD = (args: MACDParams) => {
  const headers = {
    authorization: args.token,
  };
  let suffix = args.cadence ? `?cadence=${args.cadence}` : '';
  return client.get(`/api/v0/${args.period}/macd/${args.ticker}${suffix}`, {
    headers: headers,
  });
};

export const fetchStockModel = (args: ModelParams) => {
  const headers = {
    authorization: args.token,
  };
  let queryParams = '';
  if (args.compareWith) {
    queryParams = `?compare_with=${args.compareWith}`;
  }
  return client.get(
    `/api/v0/models/${args.period}/${args.modelType}/${args.ticker}${queryParams}`,
    { headers: headers }
  );
};

export const fetchDiagnostics = (args: DiagnosticParams) => {
  const headers = {
    authorization: args.token,
  };
  let queryParams = '';
  if (args.lookbackDays) {
    queryParams = `?lookback_days=${args.lookbackDays}`;
  }
  return client.get(
    `/api/v0/model_diagnostics/${args.period}/${args.modelType}${queryParams}`,
    { headers: headers }
  );
};

export const fetchHeatmaps = (args: HeatmapParams) => {
  const headers = {
    authorization: args.token,
  };
  let queryParams = '';
  if (args.lookbackDays) {
    queryParams = `?lookback_days=${args.lookbackDays}`;
  }
  return client.get(
    `/api/v0/compare_models/${args.period}/${args.xModel}/${args.yModel}${queryParams}`,
    { headers: headers }
  );
};

export const fetchSignals = (args: SignalParams) => {
  const headers = {
    authorization: args.token,
  };

  return client.get(`/api/v0/signals`, { headers: headers });
};
