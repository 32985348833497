import { useEffect, useState, useCallback } from 'react';
import Plotly from 'plotly.js-dist-min';
import { PlotParams } from 'react-plotly.js';
import styled from 'styled-components';

interface ChartContainerProps {
  height?: string;
}

export const ChartContainer = styled.div<ChartContainerProps>`
  margin: 1rem 1rem;
  padding: 1rem 1rem;
  height: ${(props) => props.height || '300px'};
  max-width: 1000px;
  @media only screen and (max-width: 500px) {
    max-width: 360px !important;
    padding: 0rem;
    margin: 0rem;
  }
`;

const PlotlyChart = (props: PlotParams) => {
  const [container, setContainer] = useState<Plotly.PlotlyHTMLElement | null>(
    null
  );

  const newPlot = async (props: PlotParams, node: any) => {
    const tempProps = JSON.parse(JSON.stringify(props));
    let { data, layout, config } = tempProps;
    let _container = await Plotly.react(node, data, layout, config);
    setContainer(_container);
  };

  const drawCallback = useCallback(() => {
    const draw = async (props: PlotParams) => {
      const tempProps = JSON.parse(JSON.stringify(props));
      let { data, layout, config } = tempProps;
      if (layout?.yaxis2 === null) {
        delete layout.yaxis2;
      }
      if (container) {
        let _container = await Plotly.react(container, data, layout, config);
        setContainer(_container);
      }
    };
    draw(props);
  }, [props, container]);

  useEffect(() => {
    const resize = () => {
      if (container) {
        Plotly.Plots.resize(container);
      }
    };
    if (!container) {
      window.addEventListener('resize', resize);
    }
  }, [container]);

  useEffect(() => {
    drawCallback();
  }, [drawCallback]);

  return (
    <div
      ref={async (node) => {
        if (node && !container) {
          newPlot(props, node);
        }
      }}
    />
  );
};

export default PlotlyChart;
