import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchTickers, TickerParams } from './tickersAPI';

export interface TickerPayload {
  avg_true_range: string;
  close: string;
  model_score: string;
  ticker: string;
  is_buy: boolean;
  is_sell: boolean;
  willr_cross: boolean;
}

export interface TickerState {
  data: TickerPayload[];
  period: string;
  sortedBy: string;
  tickers: string[];
}

const initialState: TickerState = {
  data: [],
  period: 'daily',
  sortedBy: 'proba_up',
  tickers: [],
};

export const fetchTickersBySegment = createAsyncThunk(
  'tickers/fetchTickers',
  async (args: TickerParams) => {
    const response = await fetchTickers(args);
    return response;
  }
);

export const tickersSlice = createSlice({
  name: 'tickers',
  initialState,
  reducers: {
    setPeriod: (state, action: PayloadAction<string>) => {
      state.period = action.payload;
    },
    setSortedBy: (state, action: PayloadAction<string>) => {
      state.sortedBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTickersBySegment.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.tickers = action.payload.data
        .map((x: TickerPayload) => x.ticker)
        .sort();
    });
  },
});

export const { setSortedBy, setPeriod } = tickersSlice.actions;
export default tickersSlice.reducer;
