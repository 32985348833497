import { FC, Dispatch } from 'react';
import { RootState } from '../../app/store';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import RankingSelect from './RankingSelect';
import TickerCard from './TickerCard';

import { TickerPayload } from '../../features/tickers/tickersSlice';

const Container = styled.div`
  overflow: scroll;
  height: 85vh;
  width: 100%;
  padding: 0;
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;
const List = styled.ul`
  margin: 0;
  padding: 0;
  display: 'inline-block';
`;
const RankingContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 0rem;
  background-color: #e7e7e7;
`;

interface SSProps {
  token: string;
  onCardClick: Dispatch<string>;
}

const StockSelectorDesktop: FC<SSProps> = ({ token, onCardClick }) => {
  const tickers = useSelector((state: RootState) => state.tickers.data);

  return (
    <Container>
      <RankingContainer>
        <RankingSelect token={token} />
      </RankingContainer>
      <List>
        {tickers.map((x: TickerPayload) => (
          <TickerCard
            key={`${x.ticker}_card`}
            width={280}
            handleClick={() => onCardClick(x.ticker)}
            {...x}
          />
        ))}
      </List>
    </Container>
  );
};

export default StockSelectorDesktop;
