import { RootState } from '../../app/store';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Typography } from 'antd';
import { fetchStockModelByTicker } from '../../features/stocks/stocksSlice';
import { ModelParams } from '../../features/stocks/stocksAPI';
const { Title } = Typography;
const { Option } = Select;

interface TickerSelectProps {
  token: string;
}
const TickerSelect: FC<TickerSelectProps> = ({ token }) => {
  const dispatch = useDispatch();
  const modelType = useSelector((state: RootState) => state.stocks.modelType);
  const period = useSelector((state: RootState) => state.tickers.period);
  const ticker = useSelector((state: RootState) => state.stocks.ticker);
  const tickers = useSelector((state: RootState) => state.tickers.tickers);
  const [compareWith, setCompareWith] = useState('');

  const handleChange = (value: string) => {
    let args: ModelParams = {
      token: token,
      period: period,
      modelType: modelType,
      ticker: ticker,
    };
    if (value) {
      args.compareWith = value;
    }
    dispatch(fetchStockModelByTicker(args));
    setCompareWith(value);
  };

  return (
    <div>
      <Title level={5}>Compare With</Title>
      <Select
        value={compareWith}
        style={{ width: '12rem' }}
        onChange={handleChange}
        onSelect={handleChange}
        showSearch
      >
        {tickers.map((option) => (
          <Option key={`key_${option}`} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default TickerSelect;
