import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Watchlist,
  WatchlistItem,
  fetchWatchlists,
  fetchWatchlistItems,
  createWatchlist,
  updateWatchlist,
  createWatchlistItem,
  deleteWatchlist,
  deleteWatchlistItem,
  WatchlistItemsParams,
  WatchlistParams,
  CreateWatchlistParams,
  CreateWatchlistItemParams,
  DeleteWatchlistParams,
  DeleteWatchlistItemParams,
  UpdateWatchlistParams,
} from './watchlistAPI';

export interface WatchlistOption {
  label: string;
  value: number;
  id: string;
}

export interface WatchlistState {
  watchlists: Watchlist[];
  watchlistOptions: WatchlistOption[];
  items: WatchlistItem[];
  status: string;
  message: string;
  selected: number;
  name: string;
  description: string;
  ticker: string;
  watchlistRank: number;
  isPublic: boolean;
}

const initialState: WatchlistState = {
  watchlists: [],
  watchlistOptions: [],
  items: [],
  status: 'pending',
  message: '',
  selected: 0,
  name: '',
  description: '',
  ticker: '',
  watchlistRank: 1,
  isPublic: false,
};

export const fetchWatchlistByUserId = createAsyncThunk(
  'watchlist/fetch',
  async (args: WatchlistParams) => {
    const response = await fetchWatchlists(args);
    return response;
  }
);

export const fetchWatchlistById = createAsyncThunk(
  'watchlist/fetchItems',
  async (args: WatchlistItemsParams) => {
    const response = await fetchWatchlistItems(args);
    return response;
  }
);

export const updateWatchlistById = createAsyncThunk(
  'watchlist/update',
  async (args: UpdateWatchlistParams) => {
    const response = await updateWatchlist(args);
    return response;
  }
);

export const deleteWatchlistById = createAsyncThunk(
  'watchlist/delete',
  async (args: DeleteWatchlistParams) => {
    const response = await deleteWatchlist(args);
    return response;
  }
);

export const deleteWatchlistItemById = createAsyncThunk(
  'watchlist/deleteItems',
  async (args: DeleteWatchlistItemParams) => {
    const response = await deleteWatchlistItem(args);
    return response;
  }
);

export const createUserWatchlist = createAsyncThunk(
  'watchlist/create',
  async (args: CreateWatchlistParams) => {
    const response = await createWatchlist(args);
    return response;
  }
);

export const createUserWatchlistItem = createAsyncThunk(
  'watchlist/createItem',
  async (args: CreateWatchlistItemParams) => {
    const response = await createWatchlistItem(args);
    return response;
  }
);

export const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setTicker: (state, action: PayloadAction<string>) => {
      state.ticker = action.payload;
    },
    setWatchlistRank: (state, action: PayloadAction<number>) => {
      state.watchlistRank = action.payload;
    },
    setIsPublic: (state, action: PayloadAction<boolean>) => {
      state.isPublic = action.payload;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    reset: (state) => {
      state.message = '';
      state.status = 'pending';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWatchlistByUserId.fulfilled, (state, action) => {
        state.watchlists = action.payload.data;
        state.watchlistOptions = action.payload.data.map(
          (x: Watchlist, i: number) => ({
            value: i,
            label: x.name,
            id: x.id,
          })
        );
        state.status = 'success';
        state.message = '';
      })
      .addCase(fetchWatchlistByUserId.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchWatchlistByUserId.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to fetch trade.';
      })
      .addCase(fetchWatchlistById.fulfilled, (state, action) => {
        state.items = action.payload.data;
        state.status = 'success';
        state.message = '';
      })
      .addCase(fetchWatchlistById.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchWatchlistById.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to fetch watchlist.';
      })
      .addCase(updateWatchlistById.fulfilled, (state) => {
        state.status = 'success';
        state.message = '';
      })
      .addCase(updateWatchlistById.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateWatchlistById.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to update watchlist.';
      })
      .addCase(createUserWatchlist.fulfilled, (state, action) => {
        state.items = action.payload.data;
        state.status = 'success';
        state.message = '';
      })
      .addCase(createUserWatchlist.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(createUserWatchlist.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to delete watchlist.';
      })
      .addCase(createUserWatchlistItem.fulfilled, (state, action) => {
        state.watchlists = action.payload.data;
        state.watchlistOptions = action.payload.data.map(
          (x: Watchlist, i: number) => ({
            value: i,
            label: x.name,
            id: x.id,
          })
        );
        state.status = 'success';
        state.message = '';
      })
      .addCase(createUserWatchlistItem.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(createUserWatchlistItem.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to delete watchlist.';
      })
      .addCase(deleteWatchlistById.fulfilled, (state) => {
        state.status = 'success';
        state.message = '';
      })
      .addCase(deleteWatchlistById.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteWatchlistById.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to delete watchlist.';
      })
      .addCase(deleteWatchlistItemById.fulfilled, (state) => {
        state.status = 'success';
        state.message = '';
      })
      .addCase(deleteWatchlistItemById.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteWatchlistItemById.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to delete watchlist item.';
      });
  },
});

export const {
  setSelected,
  setName,
  setDescription,
  setWatchlistRank,
  setTicker,
  setIsPublic,
  setStatus,
  reset,
} = watchlistSlice.actions;
export default watchlistSlice.reducer;
