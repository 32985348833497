import { FC, useEffect, useState } from 'react';
import { RootState } from '../app/store';
import { Layout, Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  reset,
  setStatus,
  WatchlistOption,
  createUserWatchlist,
  fetchWatchlistByUserId,
  updateWatchlistById,
} from '../features/watchlist/watchlistSlice';
import { Watchlist } from '../features/watchlist/watchlistAPI';
import styled from 'styled-components';
import WatchlistSelect from './watchlist/WatchlistSelect';
import WatchlistTable from './watchlist/WatchlistTable';
import WatchlistTableMobile from './watchlist/WatchlistTableMobile';
import EditWatchlistForm from './watchlist/EditWatchlistForm';
import { PlusCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;
interface DashboardProps {
  watchlist: Watchlist[];
  options: WatchlistOption[];
  token: string;
  selected: number;
  onAddClick: () => void;
}

interface DashboardContainerProps {
  userId: string;
  token: string;
}

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 3rem;
`;

const StyledButton = styled(Button)`
  background-color: #a2927f !important;
  color: #371c00;
  border-color: transparent;
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  height: 3rem;
  border-radius: 0.5rem;
`;

const DesktopContainer = styled.div`
  padding-left: 1rem;
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: none;
  @media only screen and (max-width: 500px) {
    display: flex;
    max-width: 45rem;
  }
`;
const StyledContent = styled(Content)`
  overflow: scroll;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
  }
`;

const Title = styled.span`
  font-size: 1.6rem;
`;
const Description = styled.span`
  font-size: 1.2rem;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  padding: 2rem 0.5rem;
`;
const DashboardDesktop: FC<DashboardProps> = ({
  token,
  watchlist,
  options,
  selected,
  onAddClick,
}) => {
  return (
    <DesktopContainer>
      <Layout>
        <StyledContent>
          {/* <ButtonContainer>
            <StyledButton
              type='primary'
              onClick={onAddClick}
              icon={<PlusCircleOutlined />}
            >
              Add Watchlist
            </StyledButton>
          </ButtonContainer> */}
          <TitleContainer>
            <WatchlistSelect token={token} watchlist={options} />

            <Title>{watchlist[selected]?.name || 'Select a Watchlist'}</Title>
            <Description>{watchlist[selected]?.description || ''}</Description>
          </TitleContainer>
          <WatchlistTable />
        </StyledContent>
      </Layout>
    </DesktopContainer>
  );
};

const DashboardMobile: FC<DashboardProps> = ({
  token,
  watchlist,
  options,
  selected,
  onAddClick,
}) => {
  return (
    <MobileContainer>
      <Layout>
        <StyledContent>
          {/* <ButtonContainer>
            <StyledButton
              type='primary'
              onClick={onAddClick}
              icon={<PlusCircleOutlined />}
            >
              Add Watchlist
            </StyledButton>
          </ButtonContainer> */}
          <TitleContainer>
            <WatchlistSelect token={token} watchlist={options} />

            <Title>{watchlist[selected]?.name || 'Select a Watchlist'}</Title>
            <Description>{watchlist[selected]?.description || ''}</Description>
          </TitleContainer>
          <WatchlistTableMobile />
        </StyledContent>
      </Layout>
    </MobileContainer>
  );
};

const Dashboard: FC<DashboardContainerProps> = ({ userId, token }) => {
  const dispatch = useDispatch();
  const watchlist = useSelector(
    (state: RootState) => state.watchlist.watchlists
  );
  const options = useSelector(
    (state: RootState) => state.watchlist.watchlistOptions
  );
  const name = useSelector((state: RootState) => state.watchlist.name);
  const description = useSelector(
    (state: RootState) => state.watchlist.description
  );
  const isPublic = useSelector((state: RootState) => state.watchlist.isPublic);
  const selected = useSelector((state: RootState) => state.watchlist.selected);
  const fetchStatus = useSelector((state: RootState) => state.watchlist.status);
  const [modalOpen, setModalOpen] = useState(false);
  const [focusWatchlist, setFocusWatchlist] = useState('');
  const [newWatchlist, setNewWatchlist] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  useEffect(() => {
    if (fetchStatus === 'success' && newWatchlist) {
      setModalOpen(true);
      setNewWatchlist(false);
      dispatch(reset);
      return;
    }
    if (fetchStatus === 'success' && focusWatchlist != '') {
      setModalOpen(true);
      return;
    }
    if (fetchStatus === 'success' && reloadTable) {
      let args = {
        token: token,
        userId: userId,
      };
      dispatch(fetchWatchlistByUserId(args));
      dispatch(setStatus('pending'));
      setReloadTable(false);
      return;
    }
  }, [fetchStatus]);

  const handleClickOk = () => {
    setModalOpen(false);
    let args = {
      watchlistId: focusWatchlist,
      token: token,
      userId: userId,
      name: name,
      description: description,
      isPublic: isPublic,
    };

    dispatch(updateWatchlistById(args));
    setReloadTable(true);
    setFocusWatchlist('');
    dispatch(setStatus('pending'));
  };

  const showModal = () => {
    let args = {
      userId: userId,
      token: token,
      name: 'New watchlist',
      description: 'Add description',
      isPublic: false,
    };
    setNewWatchlist(true);
    dispatch(createUserWatchlist(args));
  };

  const handleClickCancel = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    let args = {
      token: token,
      userId: userId,
    };
    dispatch(fetchWatchlistByUserId(args));
  }, []);

  return (
    <>
      <Modal
        title='Add Watchlist'
        visible={modalOpen}
        onOk={handleClickOk}
        onCancel={handleClickCancel}
        okButtonProps={{ style: { backgroundColor: '#104374' } }}
      >
        <EditWatchlistForm name={name} description={description} />
      </Modal>
      <DashboardDesktop
        token={token}
        watchlist={watchlist}
        selected={selected}
        options={options}
        onAddClick={showModal}
      />
      <DashboardMobile
        token={token}
        watchlist={watchlist}
        selected={selected}
        options={options}
        onAddClick={showModal}
      />
    </>
  );
};

export default Dashboard;
