// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';

export function initializeFirebase() {
  const firebaseConfig = {
    apiKey: 'AIzaSyD55UZDoXvDXpDsf7vJVOWKYvTuizMCjow',
    authDomain: 'maneki-niko.firebaseapp.com',
    projectId: 'maneki-niko',
    storageBucket: 'maneki-niko.appspot.com',
    messagingSenderId: '1002237260735',
    appId: '1:1002237260735:web:93c4108e4ae27f61aca70a',
  };

  return initializeApp(firebaseConfig);
}
