import { FC } from 'react';
import { RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';

import {
  setName,
  setDescription,
  setIsPublic,
  createUserWatchlist,
} from '../../features/watchlist/watchlistSlice';
import styled from 'styled-components';

import { Input, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

const Container = styled.div`
  display-grid: column;
  font-family: 'Raleway', sans-serif;
`;

const FormItem = styled.div`
  display: flex;
  width: 28rem;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  .ant-input {
    width: 12rem;
  }
  .ant-picker {
    width: 12rem;
    justify-content: right;
  }
  .ant-radio-group {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

const FormItemSpan = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  width: 14rem;
  align-items: center;
`;
export interface EditWatchlistFormProps {
  name: string;
  description: string;
}
const EditWatchlistForm: FC<EditWatchlistFormProps> = ({
  name,
  description,
}) => {
  const dispatch = useDispatch();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setName(e.target.value));
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDescription(e.target.value));
  };

  const handleIsPublic = (e: CheckboxChangeEvent) => {
    dispatch(setIsPublic(e.target.checked));
  };

  return (
    <Container>
      <FormItem>
        <FormItemSpan>Name</FormItemSpan>
        <Input placeholder='Name' value={name} onChange={handleNameChange} />
      </FormItem>
      <FormItem>
        <FormItemSpan>Description</FormItemSpan>
        <Input
          placeholder='Description'
          value={description}
          onChange={handleDescriptionChange}
        />
      </FormItem>
      <FormItem>
        <Checkbox onChange={handleIsPublic}>Public?</Checkbox>
      </FormItem>
    </Container>
  );
};

export default EditWatchlistForm;
