import { FC, Dispatch } from 'react';
import styled from 'styled-components';
import { Row, Col, Layout, Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
const { Content } = Layout;

const StyledContent = styled(Content)`
  height: 85vh;
  background-color: #edeae0;
  @media only screen and (max-width: 500px) {
    width: 100%;
    display: flex;
  }
`;

const Container = styled.div`
  text-align: left;
  padding: 3rem 2rem;
  display: flex;
  grid-column: 1/-1;

  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: none;

  @media only screen and (max-width: 500px) {
    display: flex;
    justify-content: center;
    justify-self: center;
    padding: 1rem 1rem;
    width: 100%;
  }
`;

const HeroImg = styled.img`
  padding: 1rem 1rem;
  margin: 1rem 1rem;
  height: 35rem;
  @media only screen and (max-width: 500px) {
    height: 22rem;
  }
`;

const Title = styled.div`
  margin: 3rem 2rem;
  font-family: 'Raleway', sans-serif;
  font-size: 3rem;
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  line-height: initial;
  .subtitle {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 500px) {
    margin: 1rem 2rem;
    font-size: 2rem;
  }
`;

const ButtonContainer = styled.div`
  width: 5rem;
  padding: 1rem 3rem;
  @media only screen and (max-width: 500px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  background-color: #371c00 !important;
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  height: 3rem;
  border-color: #a2927f;
  border-radius: 0.5rem;
`;

interface HomepageProps {
  loggedIn: boolean;
  onClickLogin: Dispatch<boolean>;
}

const Homepage: FC<HomepageProps> = ({ loggedIn, onClickLogin }) => {
  const handleLoginClick = () => {
    onClickLogin(loggedIn);
  };
  return (
    <StyledContent>
      <Container>
        <Row gutter={8} align='middle'>
          <Col span={12}>
            <Row>
              <Title>
                <span>This is Maneki Niko</span>
                <div className='subtitle'>
                  <span>He Is Not a Financial Advisor</span>
                </div>
              </Title>
            </Row>
            <Row>
              <ButtonContainer>
                {loggedIn ? (
                  <StyledButton type='primary' onClick={handleLoginClick}>
                    Get Started
                  </StyledButton>
                ) : (
                  <StyledButton
                    type='primary'
                    onClick={handleLoginClick}
                    icon={<GoogleOutlined />}
                  >
                    Login With Google
                  </StyledButton>
                )}
              </ButtonContainer>
            </Row>
          </Col>

          <Col span={12}>
            <HeroImg src={`ManekiNikoLogo.png`} />
          </Col>
        </Row>
      </Container>
      <MobileContainer>
        <Row gutter={8} align='middle'>
          <Row>
            <Title>
              <span>This is Maneki Niko</span>
              <div className='subtitle'>
                <span>He Is Not a Financial Advisor</span>
              </div>
            </Title>
          </Row>

          <ButtonContainer>
            {loggedIn ? (
              <StyledButton type='primary' onClick={handleLoginClick}>
                Get Started
              </StyledButton>
            ) : (
              <StyledButton
                type='primary'
                onClick={handleLoginClick}
                icon={<GoogleOutlined />}
              >
                Login With Google
              </StyledButton>
            )}
          </ButtonContainer>

          <HeroImg src={`ManekiNikoLogo.png`} />
        </Row>
      </MobileContainer>
    </StyledContent>
  );
};

export default Homepage;
