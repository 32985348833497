import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

const { Column } = Table;

const WatchlistTableMobile = () => {
  const items = useSelector((state: RootState) => state.watchlist.items);

  return (
    <Table dataSource={items} size='middle' style={{ tableLayout: 'fixed' }}>
      <Column
        title='Model Date'
        dataIndex='createdAt'
        key='createdAt'
        render={(value: string) => value?.slice(0, 10)}
      />
      <Column title='Rank' dataIndex='watchlistRank' key='watchlistRank' />
      <Column title='Ticker' dataIndex='ticker' key='ticker' />
      <Column
        title='Price'
        dataIndex='close'
        key='close'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='Avg True Range'
        dataIndex='avgTrueRange'
        key='avgTrueRange'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
    </Table>
  );
};

export default WatchlistTableMobile;
