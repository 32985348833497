import { FC } from 'react';
import { RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Typography } from 'antd';

import {
  fetchMACDByTicker,
  setModelType,
} from '../../features/stocks/stocksSlice';
import { MACDParams } from '../../features/stocks/stocksAPI';
const { Title } = Typography;
const { Option } = Select;

const HOURLY_OPTIONS = [
  { value: 'slow', label: 'Slow Cross' },
  { value: 'proba', label: 'Fast Cross' },
];

interface ModelSelectProps {
  token: string;
}
const ModelSelect: FC<ModelSelectProps> = ({ token }) => {
  const dispatch = useDispatch();
  const modelType = useSelector((state: RootState) => state.stocks.modelType);
  const period = useSelector((state: RootState) => state.tickers.period);
  const ticker = useSelector((state: RootState) => state.stocks.ticker);

  const handleChange = (value: string) => {
    let args: MACDParams = {
      token: token,
      period: period,
      ticker: ticker,
    };
    if (period === 'hourly') {
      args.cadence = value;
    }
    if (period === '15m') {
      args.cadence = 'fast';
    }
    dispatch(setModelType(value));
    dispatch(fetchMACDByTicker(args));
  };

  return (
    <div>
      {period === 'hourly' && (
        <>
          <Title level={5}>Model</Title>
          <Select
            value={modelType}
            style={{ width: '12rem' }}
            onChange={handleChange}
          >
            {HOURLY_OPTIONS.map((option) => (
              <Option key={`key_${option.value}`} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </>
      )}
    </div>
  );
};

export default ModelSelect;
