import { FC } from 'react';
import { RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { TradeDetails } from '../../features/trades/tradeAPI';
import moment from 'moment';
import {
  setTicker,
  setOpenedAt,
  setClosedAt,
  setExpiresAt,
  setAction,
  setIsOption,
  setIsPut,
  setOpenPrice,
  setClosePrice,
  setStrikePrice,
  setCommission,
  setUnits,
} from '../../features/trades/tradeSlice';
import styled from 'styled-components';
import type { DatePickerProps } from 'antd';

import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { Input, InputNumber, DatePicker, Select } from 'antd';
const { Option } = Select;

const Container = styled.div`
  display-grid: column;
  font-family: 'Raleway', sans-serif;
`;

const FormItem = styled.div`
  display: flex;
  width: 28rem;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  .ant-input {
    width: 12rem;
  }
  .ant-input-number {
    width: 12rem;
  }
  .ant-picker {
    width: 12rem;
    justify-content: right;
  }
  .ant-radio-group {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

const FormItemSpan = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  width: 14rem;
  align-items: center;
`;
export interface TradeFormProps {
  authToken?: string;
}
const TradeForm: FC<TradeFormProps> = ({ authToken }) => {
  const dispatch = useDispatch();
  const trade: TradeDetails = useSelector(
    (state: RootState) => state.trades.trade
  );

  const handleTickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTicker(e.target.value));
  };

  const handleUnitsChange = (value: number) => {
    dispatch(setUnits(value));
  };

  const handleOpenPriceChange = (value: number) => {
    dispatch(setOpenPrice(value));
  };

  const handleClosePriceChange = (value: number) => {
    dispatch(setClosePrice(value));
  };

  const handleStrikePriceChange = (value: number) => {
    dispatch(setStrikePrice(value));
  };

  const handleCommissionChange = (value: number) => {
    dispatch(setCommission(value));
  };

  const handleOpenedAtChange: DatePickerProps['onChange'] = (
    date,
    dateString
  ) => {
    dispatch(setOpenedAt(dateString));
  };

  const handleClosedAtChange: DatePickerProps['onChange'] = (
    date,
    dateString
  ) => {
    dispatch(setClosedAt(dateString));
  };
  const handleExpiresAtChange: DatePickerProps['onChange'] = (
    date,
    dateString
  ) => {
    dispatch(setExpiresAt(dateString));
  };

  const handleIsPutOption = (e: RadioChangeEvent) => {
    dispatch(setIsPut(e.target.value === 'put'));
  };

  const handleSelectOption = (e: RadioChangeEvent) => {
    dispatch(setIsOption(e.target.value === 'option'));
  };

  const handleActionChange = (value: string) => {
    dispatch(setAction(value));
  };

  return (
    <Container>
      <FormItem>
        <FormItemSpan>Trade Type</FormItemSpan>
        <Radio.Group
          onChange={handleSelectOption}
          value={trade.isOption ? 'option' : 'shares'}
        >
          <Radio value={'shares'}>Shares</Radio>
          <Radio value={'option'}>Options</Radio>
        </Radio.Group>
      </FormItem>
      <FormItem>
        <FormItemSpan>Ticker</FormItemSpan>
        <Input
          placeholder='Ticker'
          value={trade.ticker}
          onChange={handleTickerChange}
        />
      </FormItem>
      <FormItem>
        <FormItemSpan>Opened At</FormItemSpan>
        <DatePicker
          value={moment(trade.openedAt)}
          onChange={handleOpenedAtChange}
        />
      </FormItem>
      <FormItem>
        <FormItemSpan>{trade.isOption ? 'Contracts' : 'Shares'}</FormItemSpan>
        <InputNumber
          placeholder={'Units'}
          value={trade.units}
          onChange={handleUnitsChange}
        />
      </FormItem>
      <FormItem>
        <FormItemSpan>
          Price {trade.isOption ? '/ Option' : '/ Share'}
        </FormItemSpan>
        <InputNumber
          placeholder='Open Price'
          value={trade.openPrice}
          step='0.01'
          addonBefore='$'
          onChange={handleOpenPriceChange}
        />
      </FormItem>

      {trade.isOption ? (
        <>
          <FormItem>
            <FormItemSpan>Option Type</FormItemSpan>
            <Radio.Group
              onChange={handleIsPutOption}
              value={trade.isPut ? 'put' : 'call'}
            >
              <Radio value={'call'}>Call</Radio>
              <Radio value={'put'}>Put</Radio>
            </Radio.Group>
          </FormItem>
          <FormItem>
            <FormItemSpan>Order Type</FormItemSpan>
            <Select defaultValue='BTO' onChange={handleActionChange}>
              <Option value='BTO'>Buy-to-Open</Option>
              <Option value='STO'>Sell-to-Open</Option>
              <Option value='BTC'>Buy-to-Close</Option>
              <Option value='STC'>Sell-to-Close</Option>
            </Select>
          </FormItem>
          <FormItem>
            <FormItemSpan>Strike Price</FormItemSpan>
            <InputNumber
              placeholder='Strike Price'
              value={trade.strikePrice}
              step='0.01'
              addonBefore='$'
              onChange={handleStrikePriceChange}
            />
          </FormItem>

          <FormItem>
            <FormItemSpan>Expires At</FormItemSpan>
            <DatePicker
              value={moment(trade.expiresAt)}
              onChange={handleExpiresAtChange}
            />
          </FormItem>
          <FormItem>
            <FormItemSpan>Commission / Fees</FormItemSpan>
            <InputNumber
              placeholder='Commission'
              value={trade.commission}
              step='0.01'
              addonBefore='$'
              onChange={handleCommissionChange}
            />
          </FormItem>
        </>
      ) : null}
      <FormItem>
        <FormItemSpan>Closed At</FormItemSpan>
        <DatePicker
          value={moment(trade.closedAt)}
          onChange={handleClosedAtChange}
        />
      </FormItem>
      <FormItem>
        <FormItemSpan>
          Close Price {trade.isOption ? '/ Option' : ' / Share'}
        </FormItemSpan>
        <InputNumber
          placeholder='Close Price'
          value={trade.closePrice}
          step='0.01'
          addonBefore='$'
          onChange={handleClosePriceChange}
        />
      </FormItem>
    </Container>
  );
};

export default TradeForm;
