import { FC, useEffect } from 'react';
import { RootState } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCandlesByTicker,
  fetchMACDByTicker,
  fetchStockModelByTicker,
} from '../features/stocks/stocksSlice';
import { fetchTickersBySegment } from '../features/tickers/tickersSlice';
import { MACDParams } from '../features/stocks/stocksAPI';
import RecommendationsDesktop from './RecommendationsDesktop';
import RecommendationsMobile from './RecommendationsMobile';

interface RecProps {
  token: string;
}

const Recommendations: FC<RecProps> = ({ token }) => {
  const dispatch = useDispatch();
  const period = useSelector((state: RootState) => state.tickers.period);
  const modelType = useSelector((state: RootState) => state.tickers.sortedBy);
  const macdModel = useSelector((state: RootState) => state.stocks.modelType);
  const ticker = useSelector((state: RootState) => state.stocks.ticker);

  useEffect(() => {
    let args = {
      token: token,
      period: period,
      modelType: modelType,
    };
    if (period !== '15m') {
      dispatch(fetchTickersBySegment(args));
    }

    let plotArgs = {
      token: token,
      period: period,
      ticker: ticker,
    };

    dispatch(fetchCandlesByTicker(plotArgs));
    let macdArgs = { ...plotArgs } as MACDParams;
    if (period === 'hourly') {
      macdArgs.cadence = macdModel;
    }
    dispatch(fetchMACDByTicker(macdArgs));
    let modelArgs = {
      modelType: 'proba_up',
      ...plotArgs,
    };
    if (period !== '15m') {
      dispatch(fetchStockModelByTicker(modelArgs));
    }
  }, [dispatch, period]);

  return (
    <>
      <RecommendationsDesktop token={token} />
      <RecommendationsMobile token={token} />
    </>
  );
};

export default Recommendations;
