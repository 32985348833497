import { client } from '../../utils/fetch';
import React from 'react';

export interface TradeParams {
  tradeId: string;
}

export interface TradeSummary {
  key?: React.Key;
  tradeId: string;
  openedAt: string;
  ticker: string;
  openPrice: number;
  units: number;
  costBasis: number;
  closedAt?: string;
  closePrice: number;
  profit: number;
  profitPercent: number;
}

export interface TradeDetails {
  id?: string;
  ticker: string;
  openedAt: string;
  closedAt?: string;
  action: string;
  isOption: boolean;
  units: number;
  openPrice: number;
  closePrice?: number;
  expiresAt?: string;
  strikePrice?: number;
  isPut: boolean;
  commission: number;
}

export interface TradeParams {
  tradeId: string;
  authToken: string;
}

export interface CreateTradeParams {
  userId: string;
  authToken: string;
  payload: TradeDetails;
}

export interface UpdateTradeParams {
  tradeId: string;
  payload: TradeDetails;
  authToken: string;
}

export const fetchTrade = ({ tradeId, authToken }: TradeParams) => {
  const headers = {
    authorization: authToken,
  };
  return client.get(`/api/v0/trades/${tradeId}`, { headers: headers });
};

export const createTrade = ({
  userId,
  authToken,
  payload,
}: CreateTradeParams) => {
  const headers = {
    authorization: authToken,
  };
  return client.post(
    `/api/v0/trades/create`,
    { ...payload, userId: userId },
    { headers: headers }
  );
};

export const updateTrade = ({
  tradeId,
  payload,
  authToken,
}: UpdateTradeParams) => {
  const headers = {
    authorization: authToken,
  };
  return client.post(
    `/api/v0/trades/update/${tradeId}`,
    { ...payload },
    { headers: headers }
  );
};

export const deleteTrade = ({ tradeId, authToken }: TradeParams) => {
  const headers = {
    authorization: authToken,
  };
  return client.delete(`/api/v0/trades/${tradeId}`, { headers: headers });
};

export interface UserTradesParams {
  state: string;
  userId: string;
  page: number;
  authToken: string;
}
export const fetchUserTrades = ({
  state,
  userId,
  page,
  authToken,
}: UserTradesParams) => {
  const headers = {
    authorization: authToken,
  };
  return client.get(`/api/v0/trades/${state}/${userId}/${page}`, {
    headers: headers,
  });
};

export interface UserTradeStatsParams {
  userId: string;
  authToken: string;
}

export const fetchUserTradeStats = ({
  userId,
  authToken,
}: UserTradeStatsParams) => {
  const headers = {
    authorization: authToken,
  };
  return client.get(`/api/v0/trade_stats/${userId}`, {
    headers: headers,
  });
};
