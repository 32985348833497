import { FC } from 'react';
import { useState } from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from './app/store';
import Dashboard from './components/Dashboard';
import Recommendations from './components/Recommendations';
import RecommendationsMobile from './components/RecommendationsMobile';
import Homepage from './components/Homepage';
import TradeJournal from './components/TradeJournal';
import DayTrade from './components/DayTrade';
import Diagnostics from './components/Diagnostics';
import DiagnosticsMobile from './components/DiagnosticsMobile';
import Nav from './components/Nav';
import NavMobile from './components/NavMobile';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import './App.css';

const { Footer } = Layout;

const PageFooter = styled(Footer)`
  padding: 0 1rem;
  display: flex;
  height: 15vh;
  background-color: #177797 !important;
`;

const App: FC = () => {
  const auth = getAuth();
  const [page, setPage] = useState('landing');
  const userId = useSelector((state: RootState) => state.auth.userId);
  const token = useSelector((state: RootState) => state.auth.token);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);

  const handleLogin = async (userIsLoggedIn: boolean) => {
    if (userIsLoggedIn) {
      setPage('recs');
    } else {
      try {
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        await signInWithPopup(auth, provider);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const signOut = async () => {
    await auth.signOut();
    setPage('landing');
  };

  const content = (): JSX.Element => {
    switch (page) {
      case 'landing':
        return <Homepage onClickLogin={handleLogin} loggedIn={loggedIn} />;
      case 'watchlist':
        return <Dashboard userId={userId} token={token} />;
      case 'daytrade':
        return <DayTrade token={token} />;
      case 'recs':
        return (
          <>
            <Recommendations token={token} />
            <RecommendationsMobile token={token} />
          </>
        );
      case 'trades':
        return <TradeJournal userId={userId} token={token} />;
      case 'diagnostics':
        return (
          <>
            <Diagnostics token={token} />
            <DiagnosticsMobile token={token} />
          </>
        );
      default:
        return <Recommendations token={token} />;
    }
  };

  return (
    <Layout className='layout'>
      <Nav
        page={page}
        setPage={setPage}
        loggedIn={loggedIn}
        onSignOut={signOut}
      />
      <NavMobile
        page={page}
        setPage={setPage}
        loggedIn={loggedIn}
        onSignOut={signOut}
      />
      {content()}
      <PageFooter />
    </Layout>
  );
};

export default App;
