import { FC, useEffect } from 'react';
import { RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Typography } from 'antd';
import {
  setStatus,
  setSelected,
  fetchWatchlistById,
  WatchlistOption,
} from '../../features/watchlist/watchlistSlice';
const { Title } = Typography;
const { Option } = Select;

interface WatchlistSelectProps {
  token: string;
  watchlist: WatchlistOption[];
}
const WatchlistSelect: FC<WatchlistSelectProps> = ({ token, watchlist }) => {
  const dispatch = useDispatch();
  const selected = useSelector((state: RootState) => state.watchlist.selected);
  const fetchStatus = useSelector((state: RootState) => state.watchlist.status);

  useEffect(() => {
    if (
      watchlist[selected]?.id !== null &&
      watchlist[selected]?.id !== undefined
    ) {
      let args = {
        token: token,
        watchlistId: watchlist[selected].id,
      };
      dispatch(fetchWatchlistById(args));
    }
  }, [watchlist]);

  useEffect(() => {
    if (
      watchlist[selected]?.id !== null &&
      watchlist[selected]?.id !== undefined &&
      fetchStatus === 'pending'
    ) {
      let args = {
        token: token,
        watchlistId: watchlist[selected].id,
      };
      dispatch(fetchWatchlistById(args));
    }
  }, [selected, watchlist]);

  const handleChange = (value: number) => {
    dispatch(setSelected(value));
    dispatch(setStatus('pending'));
  };

  return (
    <div>
      <Title level={5}>Select Watchlist</Title>
      <Select
        value={selected}
        style={{ width: '12rem' }}
        onChange={handleChange}
      >
        {watchlist.map((option) => (
          <Option key={`key_${option.value}`} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default WatchlistSelect;
