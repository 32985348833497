import { client } from '../../utils/fetch';

export interface TickerParams {
  token: string;
  period: string;
  modelType: string;
}

export const fetchTickers = (args: TickerParams) => {
  const headers = { authorization: args.token };
  return client.get(`/api/v0/tickers/${args.period}/${args.modelType}`, {
    headers: headers,
  });
};
