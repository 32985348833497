import { FC } from 'react';
import { RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Typography } from 'antd';
import { setPeriod } from '../../features/tickers/tickersSlice';
import { fetchTickersBySegment } from '../../features/tickers/tickersSlice';
const { Title } = Typography;
const { Option } = Select;

const OPTIONS = [
  { value: 'daily', label: 'Daily' },
  { value: 'hourly', label: 'Hourly' },
  { value: '15m', label: '15Min' },
];
interface PeriodSelectProps {
  token: string;
}
const PeriodSelect: FC<PeriodSelectProps> = ({ token }) => {
  const dispatch = useDispatch();
  const sortField = useSelector((state: RootState) => state.tickers.sortedBy);
  const period = useSelector((state: RootState) => state.tickers.period);

  const handleChange = (value: string) => {
    let args = {
      token: token,
      period: value,
      modelType: sortField,
    };
    if (value !== '15m') {
      dispatch(fetchTickersBySegment(args));
    }
    dispatch(setPeriod(value));
  };

  return (
    <div>
      <Title level={5}>Period</Title>
      <Select value={period} style={{ width: '12rem' }} onChange={handleChange}>
        {OPTIONS.map((option) => (
          <Option key={`key_${option.value}`} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default PeriodSelect;
