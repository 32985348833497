import { FC, useEffect, useState } from 'react';
import { Col, Row, Select, Typography } from 'antd';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import ReturnsChart from './diagnostics/ReturnsChart';
import DrawdownChart from './diagnostics/DrawdownChart';
import ReturnsHeatmap from './diagnostics/ReturnsHeatmap';
import DrawdownHeatmap from './diagnostics/DrawdownHeatmap';
import {
  fetchModelDiagnostics,
  fetchModelHeatmaps,
} from '../features/stocks/stocksSlice';
const { Title } = Typography;
const { Option } = Select;

const DAILY_OPTIONS = [
  { value: 'proba_up', label: 'P(7% Gain in 5 days)' },
  { value: 'proba_up_long', label: 'P(7% Gain in 10 days)' },
  { value: 'proba_bear', label: 'P(Bearish Cross in 5 days)' },
  { value: 'proba_bull', label: 'P(Bullish Cross in 5 days)' },
];
const HOURLY_OPTIONS = [
  { value: 'proba_up', label: 'P(3% Gain in 10 Hrs)' },
  { value: 'proba_up_long', label: 'P(5% Gain in 10 Hrs)' },
  { value: 'proba_bear', label: 'P(Bearish Cross in 10 Hrs)' },
  { value: 'proba_bull', label: 'P(Bullish Cross in 10 Hrs)' },
];
const Container = styled.div`
  display: none;
  @media only screen and (max-width: 500px) {
    padding: 1rem 1rem;
    background-color: #e7e7e7;
    display: flex;
    justify-content: center;
    height: 85vh;
    overflow: scroll;
  }
`;
const StyledRow = styled(Row)`
  padding: 1rem 1rem;
  width: 360px !important;
`;

interface DiagnosticsParams {
  token: string;
}
const DiagnosticsMobile: FC<DiagnosticsParams> = ({ token }) => {
  const dispatch = useDispatch();
  const [modelType, setModelType] = useState('proba_up');
  const [compareModel, setCompareModel] = useState('proba_bull');
  const [period, setPeriod] = useState('daily');

  useEffect(() => {
    let args = {
      token: token,
      period: 'daily',
      modelType: 'proba_up',
    };
    dispatch(fetchModelDiagnostics(args));

    let heatArgs = {
      token: token,
      period: 'daily',
      xModel: 'proba_up',
      yModel: 'proba_bull',
    };
    dispatch(fetchModelHeatmaps(heatArgs));
  }, []);

  const handleModelSelect = (value: string) => {
    let args = {
      token: token,
      period: period,
      modelType: value,
    };
    dispatch(fetchModelDiagnostics(args));
    setModelType(value);
    let heatArgs = {
      token: token,
      period: period,
      xModel: value,
      yModel: compareModel,
    };
    dispatch(fetchModelHeatmaps(heatArgs));
  };

  const handlePeriodSelect = (value: string) => {
    let args = {
      token: token,
      period: value,
      modelType: modelType,
    };
    dispatch(fetchModelDiagnostics(args));
    setPeriod(value);

    let heatArgs = {
      token: token,
      period: value,
      xModel: modelType,
      yModel: compareModel,
    };
    dispatch(fetchModelHeatmaps(heatArgs));
  };

  const handleCompareSelect = (value: string) => {
    let args = {
      token: token,
      period: period,
      xModel: modelType,
      yModel: value,
    };
    dispatch(fetchModelHeatmaps(args));
    setCompareModel(value);
  };

  return (
    <Container>
      <Col span={24}>
        <StyledRow gutter={8}>
          <Title level={5} style={{ paddingRight: '1rem' }}>
            Period
          </Title>
          <Select
            value={period}
            style={{ width: '12rem' }}
            onChange={handlePeriodSelect}
          >
            <Option key={`key_daily`} value={'daily'}>
              Daily
            </Option>
            <Option key={`key_hourly`} value={'hourly'}>
              Hourly
            </Option>
          </Select>
        </StyledRow>
        <StyledRow>
          <Title level={5} style={{ paddingRight: '1rem' }}>
            Model
          </Title>
          <Select
            value={modelType}
            style={{ width: '12rem' }}
            onChange={handleModelSelect}
          >
            {(period === 'daily' ? DAILY_OPTIONS : HOURLY_OPTIONS).map(
              (option) => (
                <Option key={`key_${option.value}`} value={option.value}>
                  {option.label}
                </Option>
              )
            )}
          </Select>
        </StyledRow>
        <StyledRow>
          <Title level={5} style={{ paddingRight: '1rem' }}>
            Compare With
          </Title>
          <Select
            value={compareModel}
            style={{ width: '12rem' }}
            onChange={handleCompareSelect}
          >
            {(period === 'daily' ? DAILY_OPTIONS : HOURLY_OPTIONS).map(
              (option) => (
                <Option key={`key_comp_${option.value}`} value={option.value}>
                  {option.label}
                </Option>
              )
            )}
          </Select>
        </StyledRow>
        <StyledRow gutter={8}>
          <ReturnsChart />
        </StyledRow>
        <StyledRow>
          <DrawdownChart />
        </StyledRow>
        <StyledRow gutter={8}>
          <ReturnsHeatmap />
        </StyledRow>
        <StyledRow>
          <DrawdownHeatmap />
        </StyledRow>
      </Col>
    </Container>
  );
};

export default DiagnosticsMobile;
