import { FC, useState, useEffect } from 'react';
import { Layout, Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import TradeForm from './trades/TradeForm';
import TradeTable from './trades/TradeTable';
import TradeSummaryStats from './trades/TradeSummaryStats';
import styled from 'styled-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import { TradeSummary } from '../features/trades/tradeAPI';
import {
  fetchTradesByUserId,
  fetchTradeStatsByUserId,
  fetchTradeById,
  updateTradeById,
  createNewTrade,
  defaultTrade,
  reset,
  setStatus,
} from '../features/trades/tradeSlice';
const { Content } = Layout;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 3rem;
`;

const StyledButton = styled(Button)`
  background-color: #40344e !important;
  border-color: transparent;
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  height: 3rem;
`;

const SummaryTable = styled.div`
  padding: 1rem 3rem;
`;

const SummaryStatsContainer = styled.div`
  padding: 1rem 1rem;
  margin: 0 3rem;
  height: 320px;
  background-color: #e7e7e7;
  @media only screen and (max-width: 500px) {
    padding: 1rem;
    margin: 0.5rem;
    height: 500px;
  }
`;
export interface TradeJournalProps {
  userId: string;
  token: string;
}

const TradeJournal: FC<TradeJournalProps> = ({ userId, token }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [focusTrade, setFocusTrade] = useState('');
  const [newTrade, setNewTrade] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const trade = useSelector((state: RootState) => state.trades.trade);
  const fetchStatus = useSelector((state: RootState) => state.trades.status);

  useEffect(() => {
    let args = {
      userId: userId,
      authToken: token,
    };
    dispatch(fetchTradeStatsByUserId(args));
    dispatch(setStatus('pending'));
  }, []);

  useEffect(() => {
    if (fetchStatus === 'success' && newTrade) {
      setModalOpen(true);
      setNewTrade(false);
      dispatch(reset);
      return;
    }
    if (fetchStatus === 'success' && focusTrade != '') {
      setModalOpen(true);
      return;
    }
    if (fetchStatus === 'success' && reloadTable) {
      let args = {
        state: 'all',
        userId: userId,
        authToken: token,
        page: 1,
      };
      dispatch(fetchTradesByUserId(args));
      dispatch(setStatus('pending'));
      setReloadTable(false);
    }
  }, [fetchStatus]);

  const dispatch = useDispatch();
  const showModal = () => {
    let args = {
      userId: userId,
      authToken: token,
      payload: defaultTrade,
    };
    setNewTrade(true);
    dispatch(createNewTrade(args));
  };

  const handleClickOk = () => {
    setModalOpen(false);
    let args = {
      tradeId: trade?.id || '',
      authToken: token,
      payload: trade,
    };

    dispatch(updateTradeById(args));
    setReloadTable(true);
    setFocusTrade('');
    let statsArgs = {
      userId: userId,
      authToken: token,
    };
    dispatch(fetchTradeStatsByUserId(statsArgs));
    dispatch(setStatus('pending'));
  };

  const handleClickCancel = () => {
    setModalOpen(false);
    setFocusTrade('');
  };

  const handleClickRow = (record: TradeSummary) => {
    let args = {
      tradeId: record.tradeId,
      authToken: token,
    };
    dispatch(fetchTradeById(args));
    setFocusTrade(record.tradeId);
  };

  return (
    <Layout>
      <Content style={{ height: '85vh', overflow: 'scroll' }}>
        <ButtonContainer>
          <StyledButton
            type='primary'
            onClick={showModal}
            icon={<PlusCircleOutlined />}
          >
            New Trade
          </StyledButton>
        </ButtonContainer>

        <Modal
          title='Edit Trade'
          visible={modalOpen}
          onOk={handleClickOk}
          onCancel={handleClickCancel}
          okButtonProps={{ style: { backgroundColor: '#104374' } }}
        >
          <TradeForm />
        </Modal>
        <SummaryStatsContainer>
          <TradeSummaryStats />
        </SummaryStatsContainer>
        <SummaryTable>
          <TradeTable onClickRow={handleClickRow} />
        </SummaryTable>
      </Content>
    </Layout>
  );
};

export default TradeJournal;
