import { FC } from 'react';
import { RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Typography } from 'antd';
import { setSortedBy } from '../../features/tickers/tickersSlice';
import { fetchTickersBySegment } from '../../features/tickers/tickersSlice';
const { Title } = Typography;
const { Option } = Select;

const DAILY_OPTIONS = [
  { value: 'proba_up', label: 'P(7% Gain in 1 Week)' },
  { value: 'proba_up_long', label: 'Bullish Signals' },
  { value: 'proba_bull', label: 'P(Bullish Cross) in 1 Week' },
  { value: 'proba_bear', label: 'P(Bearish Cross in 1 Week)' },
];
const HOURLY_OPTIONS = [
  { value: 'proba_up', label: 'P(3% Gain in 10 Hrs)' },
  { value: 'proba_up_long', label: 'Bullish Signal' },
  { value: 'slow_bull', label: 'P(Bullish in 5 Hrs)' },
  { value: 'slow_bear', label: 'P(Bearish in 5 Hrs)' },
];
interface RankingSelectProps {
  token: string;
}
const RankingSelect: FC<RankingSelectProps> = ({ token }) => {
  const dispatch = useDispatch();
  const sortField = useSelector((state: RootState) => state.tickers.sortedBy);
  const period = useSelector((state: RootState) => state.tickers.period);
  const model = useSelector((state: RootState) => state.stocks.modelType);

  const handleChange = (value: string) => {
    let args = {
      token: token,
      period: period,
      modelType: value,
    };
    if (model === 'fast' && period === 'hourly') {
      args.modelType = value.replace('slow', 'proba');
    }
    dispatch(fetchTickersBySegment(args));
    dispatch(setSortedBy(value));
  };

  return (
    <div>
      <Title level={5}>Ticker Ranking</Title>
      <Select
        value={sortField}
        style={{ width: '12rem' }}
        onChange={handleChange}
      >
        {(period === 'daily' ? DAILY_OPTIONS : HOURLY_OPTIONS).map((option) => (
          <Option key={`key_${option.value}`} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default RankingSelect;
