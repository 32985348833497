import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TradeDetails,
  TradeSummary,
  TradeParams,
  UserTradesParams,
  UserTradeStatsParams,
  CreateTradeParams,
  UpdateTradeParams,
  fetchTrade,
  fetchUserTrades,
  fetchUserTradeStats,
  createTrade,
  updateTrade,
  deleteTrade,
} from './tradeAPI';
import { PlotParams } from 'react-plotly.js';

export interface TradeStatsSummary {
  weekly: PlotParams;
  trades: number;
  profit: number;
  winRate: number;
  activeCapital: number;
}

export interface TradePayload extends TradeDetails {
  userId: string;
}

export interface TradeState {
  userTrades: TradeSummary[];
  trade: TradeDetails;
  stats: TradeStatsSummary;
  status: string;
  message: string;
}

export const defaultTrade: TradeDetails = {
  id: undefined,
  ticker: 'SPY',
  openedAt: new Date().toISOString().slice(0, 10),
  closedAt: undefined,
  action: 'BTO',
  isOption: false,
  units: 0,
  openPrice: 0,
  closePrice: undefined,
  expiresAt: undefined,
  strikePrice: undefined,
  isPut: false,
  commission: 0,
};

const initialState: TradeState = {
  userTrades: [],
  trade: defaultTrade,
  stats: {
    weekly: {
      data: [],
      layout: {},
    },
    trades: 0,
    winRate: 0,
    profit: 0,
    activeCapital: 0,
  },
  status: 'pending',
  message: '',
};

export const fetchTradeById = createAsyncThunk(
  'trades/fetchTrade',
  async (args: TradeParams) => {
    const response = await fetchTrade(args);
    return response;
  }
);

export const createNewTrade = createAsyncThunk(
  'trades/create',
  async (args: CreateTradeParams) => {
    const response = await createTrade(args);
    return response;
  }
);

export const updateTradeById = createAsyncThunk(
  'trades/update',
  async (args: UpdateTradeParams) => {
    const response = await updateTrade(args);
    return response;
  }
);

export const deleteTradeById = createAsyncThunk(
  'trades/delete',
  async (args: TradeParams) => {
    const response = await deleteTrade(args);
    return response;
  }
);

export const fetchTradesByUserId = createAsyncThunk(
  'trades/fetchTrades',
  async (args: UserTradesParams) => {
    const response = await fetchUserTrades(args);
    return response;
  }
);

export const fetchTradeStatsByUserId = createAsyncThunk(
  'trades/fetchTradeStats',
  async (args: UserTradeStatsParams) => {
    const response = await fetchUserTradeStats(args);
    return response;
  }
);

export const tradeSlice = createSlice({
  name: 'trades',
  initialState,
  reducers: {
    setTicker: (state, action: PayloadAction<string>) => {
      state.trade.ticker = action.payload;
    },
    setOpenedAt: (state, action: PayloadAction<string>) => {
      state.trade.openedAt = action.payload;
    },
    setClosedAt: (state, action: PayloadAction<string>) => {
      state.trade.closedAt = action.payload;
    },
    setExpiresAt: (state, action: PayloadAction<string>) => {
      state.trade.expiresAt = action.payload;
    },
    setAction: (state, action: PayloadAction<string>) => {
      state.trade.action = action.payload;
    },
    setIsOption: (state, action: PayloadAction<boolean>) => {
      state.trade.isOption = action.payload;
    },
    setIsPut: (state, action: PayloadAction<boolean>) => {
      state.trade.isPut = action.payload;
    },
    setUnits: (state, action: PayloadAction<number>) => {
      state.trade.units = action.payload;
    },
    setOpenPrice: (state, action: PayloadAction<number>) => {
      state.trade.openPrice = action.payload;
    },
    setClosePrice: (state, action: PayloadAction<number>) => {
      state.trade.closePrice = action.payload;
    },
    setStrikePrice: (state, action: PayloadAction<number>) => {
      state.trade.strikePrice = action.payload;
    },
    setCommission: (state, action: PayloadAction<number>) => {
      state.trade.commission = action.payload;
    },
    update: (state, action: PayloadAction<TradeDetails>) => {
      state.trade = action.payload;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    reset: (state) => {
      state.message = '';
      state.status = 'pending';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradeById.fulfilled, (state, action) => {
        state.trade = action.payload;
        state.status = 'success';
        state.message = '';
      })
      .addCase(fetchTradeById.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchTradeById.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to fetch trade.';
      })
      .addCase(createNewTrade.fulfilled, (state, action) => {
        state.trade = action.payload;
        state.status = 'success';
        state.message = '';
      })
      .addCase(createNewTrade.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(createNewTrade.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to create new trade.';
      })
      .addCase(updateTradeById.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = '';
      })
      .addCase(updateTradeById.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateTradeById.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to update trade.';
      })
      .addCase(deleteTradeById.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = '';
      })
      .addCase(deleteTradeById.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteTradeById.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to delete trade.';
      })
      .addCase(fetchTradesByUserId.fulfilled, (state, action) => {
        state.userTrades = action.payload.data.map(
          (x: TradeSummary, i: number) => {
            return { ...x, key: `${i}` };
          }
        );
        state.status = 'success';
        state.message = '';
      })
      .addCase(fetchTradesByUserId.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchTradesByUserId.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to fetch trades.';
      })
      .addCase(fetchTradeStatsByUserId.fulfilled, (state, action) => {
        state.stats.winRate = action.payload.winRate;
        state.stats.profit = action.payload.profit;
        state.stats.activeCapital = action.payload.activeCapital;
        state.stats.trades = action.payload.trades;
        state.stats.weekly = action.payload.weekly as PlotParams;
        state.status = 'success';
        state.message = '';
      })
      .addCase(fetchTradeStatsByUserId.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchTradeStatsByUserId.rejected, (state) => {
        state.status = 'pending';
        state.message = 'Unable to fetch trade stats.';
      });
  },
});

export const {
  update,
  reset,
  setTicker,
  setOpenedAt,
  setClosedAt,
  setExpiresAt,
  setAction,
  setIsOption,
  setIsPut,
  setOpenPrice,
  setClosePrice,
  setStrikePrice,
  setCommission,
  setUnits,
  setStatus,
} = tradeSlice.actions;
export default tradeSlice.reducer;
