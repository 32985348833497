import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';

import PlotlyChart, { ChartContainer } from '../PlotlyChart';

const CandleChart = () => {
  const data = useSelector((state: RootState) => state.stocks.candles);

  return (
    <ChartContainer>
      <PlotlyChart {...data} />
    </ChartContainer>
  );
};

export default CandleChart;
