import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PlotlyChart, { ChartContainer } from '../PlotlyChart';
import styled from 'styled-components';

const DesktopContainer = styled.div`
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: none;
  @media only screen and (max-width: 500px) {
    display: flex;
  }
`;

const SummaryContainer = styled.div`
  font-family: 'Raleway', sans-serif;
  font-size: 1.5rem;
  background-color: #e7e7e7;
  height: 260px;
  padding: 1rem 1rem;
  display: grid;
  grid-column: 1/-1;
  @media only screen and (max-width: 500px) {
    height: 100px;
    font-size: 1.4rem;
  }
`;

const TradeSummaryStats = () => {
  const trades = useSelector((state: RootState) => state.trades.stats.trades);
  const winRate = useSelector((state: RootState) => state.trades.stats.winRate);
  const activeCapital = useSelector(
    (state: RootState) => state.trades.stats.activeCapital
  );
  const profit = useSelector((state: RootState) => state.trades.stats.profit);
  const data = useSelector((state: RootState) => state.trades.stats.weekly);

  return (
    <div>
      <DesktopContainer>
        <Row gutter={8}>
          <Col span={18}>
            <ChartContainer height='280px'>
              <PlotlyChart {...data} />
            </ChartContainer>
          </Col>
          <Col span={6}>
            <SummaryContainer>
              <span>
                <b>Trades: </b>
                {`${trades}`}
              </span>
              <span>
                <b>Profit: </b>
                {`$${profit.toFixed(2)}`}
              </span>
              <span>
                <b>Win Rate: </b>
                {`${(100 * winRate).toFixed(2)}%`}
              </span>
              <span>
                <b>Active Capital: </b>
                {`$${activeCapital.toFixed(2)}`}
              </span>
            </SummaryContainer>
          </Col>
        </Row>
      </DesktopContainer>
      <MobileContainer>
        <Col span={24}>
          <Row gutter={8}>
            <div style={{ width: '360 px! important' }}>
              <ChartContainer>
                <PlotlyChart {...data} />
              </ChartContainer>
            </div>
          </Row>
          <Row>
            <SummaryContainer>
              <span>
                <b>Trades: </b>
                {`${trades}`}
              </span>
              <span>
                <b>Profit: </b>
                {`$${profit.toFixed(2)}`}
              </span>
              <span>
                <b>Win Rate: </b>
                {`${(100 * winRate).toFixed(2)}%`}
              </span>
              <span>
                <b>Active Capital: </b>
                {`$${activeCapital.toFixed(2)}`}
              </span>
            </SummaryContainer>
          </Row>
        </Col>
      </MobileContainer>
    </div>
  );
};

export default TradeSummaryStats;
