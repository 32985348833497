import { FC } from 'react';

import StockSelector from './recommendations/StockSelector';
import styled from 'styled-components';

const Container = styled.div`
  display: none;
  @media only screen and (max-width: 500px) {
    display: flex;
  }
`;
interface RecProps {
  token: string;
}

const RecommendationsMobile: FC<RecProps> = ({ token }) => {
  return (
    <Container>
      <StockSelector token={token} />
    </Container>
  );
};

export default RecommendationsMobile;
