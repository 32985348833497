import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlotDataParams, fetchPlotData } from './dayTradeAPI';
import { PlotParams } from 'react-plotly.js';

export interface PlotResponse {
  candles: PlotParams;
  rsi: PlotParams;
  macd: PlotParams;
  vol: PlotParams;
}

export interface DayTradeState {
  data: PlotResponse;
  ticker: string;
}

const initialData: PlotParams = {
  data: [],
  layout: {},
};

const initialState: DayTradeState = {
  data: {
    candles: initialData,
    rsi: initialData,
    macd: initialData,
    vol: initialData,
  },
  ticker: 'SPY',
};

export const fetchDataByTicker = createAsyncThunk(
  'daytrade/get',
  async (args: PlotDataParams) => {
    const response = await fetchPlotData(args);
    return response;
  }
);

export const dayTradeSlice = createSlice({
  name: 'dayTrade',
  initialState,
  reducers: {
    setTicker: (state, action: PayloadAction<string>) => {
      state.ticker = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataByTicker.fulfilled, (state, action) => {
      state.data = action.payload as PlotResponse;
    });
  },
});

export const { setTicker } = dayTradeSlice.actions;
export default dayTradeSlice.reducer;
