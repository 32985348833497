import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';

import PlotlyChart, { ChartContainer } from '../PlotlyChart';

const MACDChart = () => {
  const data = useSelector((state: RootState) => state.stocks.macd);

  return (
    <ChartContainer height='600px'>
      <PlotlyChart {...data} />
    </ChartContainer>
  );
};

export default MACDChart;
