import { client } from '../../utils/fetch';

export interface Watchlist {
  id: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
}

export interface WatchlistItem {
  id: string;
  userId: string;
  name: string;
  description: string;
  watchlistItemId: string;
  ticker: string;
  close: number;
  recentClose: number;
  delta: number;
  watchlistRank: number;
  avgTrueRange: number;
  highResistance: number;
  lowSupport: number;
  bullishPeriod: number;
  gt5PWeekProba: number;
  gt7P2WeekProba: number;
  gt5PWedProba: number;
  createdAt: string;
}

export interface WatchlistItems {
  data: WatchlistItem[];
}
export interface Watchlists {
  data: Watchlist[];
}

export interface WatchlistItemsParams {
  watchlistId: string;
  token: string;
}
export interface WatchlistParams {
  userId: string;
  token: string;
}

export const fetchWatchlists = (args: WatchlistParams) => {
  const headers = {
    authorization: args.token,
  };
  return client.get(`/api/v0/watchlist/list/${args.userId}`, {
    headers: headers,
  });
};

export const fetchWatchlistItems = (args: WatchlistItemsParams) => {
  const headers = {
    authorization: args.token,
  };
  return client.get(`/api/v0/watchlist/${args.watchlistId}`, {
    headers: headers,
  });
};

export interface CreateWatchlistParams {
  token: string;
  userId: string;
  name: string;
  description: string;
  isPublic: boolean;
}

export const createWatchlist = (args: CreateWatchlistParams) => {
  const headers = {
    authorization: args.token,
  };
  const payload = {
    userId: args.userId,
    name: args.name,
    description: args.description,
    isPublic: args.isPublic,
  };
  return client.post(
    `/api/v0/watchlist/create`,
    { ...payload },
    { headers: headers }
  );
};

export interface UpdateWatchlistParams {
  token: string;
  watchlistId: string;
  userId: string;
  name: string;
  description: string;
  isPublic: boolean;
}

export const updateWatchlist = (args: UpdateWatchlistParams) => {
  const headers = {
    authorization: args.token,
  };
  const payload = {
    userId: args.userId,
    name: args.name,
    description: args.description,
    isPublic: args.isPublic,
  };
  return client.post(
    `/api/v0/watchlist/update/${args.watchlistId}`,
    { ...payload },
    { headers: headers }
  );
};

export interface CreateWatchlistItemParams {
  token: string;
  watchlistId: string;
  ticker: string;
  watchlistRank: string;
}

export const createWatchlistItem = (args: CreateWatchlistItemParams) => {
  const headers = {
    authorization: args.token,
  };
  const payload = {
    watchlistId: args.watchlistId,
    ticker: args.ticker,
    watchlistRank: args.watchlistRank,
  };
  return client.post(
    `/api/v0/watchlist/create-item`,
    { ...payload },
    { headers: headers }
  );
};

export interface DeleteWatchlistParams {
  token: string;
  watchlistId: string;
}
export const deleteWatchlist = ({
  token,
  watchlistId,
}: DeleteWatchlistParams) => {
  const headers = {
    authorization: token,
  };
  return client.delete(`/api/v0/watchlist/delete/${watchlistId}`, {
    headers: headers,
  });
};

export interface DeleteWatchlistItemParams {
  token: string;
  watchlistItemId: string;
}
export const deleteWatchlistItem = ({
  token,
  watchlistItemId,
}: DeleteWatchlistItemParams) => {
  const headers = {
    authorization: token,
  };
  return client.delete(`/api/v0/watchlist/items/delete/${watchlistItemId}`, {
    headers: headers,
  });
};
