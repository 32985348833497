import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

const { Column } = Table;

const WatchlistTable = () => {
  const items = useSelector((state: RootState) => state.watchlist.items);

  return (
    <Table dataSource={items} size='middle' style={{ tableLayout: 'fixed' }}>
      <Column
        title='Model Date'
        dataIndex='createdAt'
        key='createdAt'
        // render={(value: string) => value?.slice(0, 10)}
      />
      <Column title='Rank' dataIndex='watchlistRank' key='watchlistRank' />
      <Column title='Ticker' dataIndex='ticker' key='ticker' />
      <Column
        title='Price'
        dataIndex='close'
        key='close'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='Latest'
        dataIndex='recentClose'
        key='recentClose'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='% Change'
        dataIndex='delta'
        key='delta'
        render={(value: number) => `${value?.toFixed(2)}%`}
      />
      <Column
        title='Support'
        dataIndex='lowSupport'
        key='lowSupport'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='Resistance'
        dataIndex='highResistance'
        key='highResistance'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='Avg True Range'
        dataIndex='avgTrueRange'
        key='avgTrueRange'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='Lower BB'
        dataIndex='bbLower'
        key='bbLower'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='Mid BB'
        dataIndex='bbMiddle'
        key='bbMiddle'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='Upper BB'
        dataIndex='bbUpper'
        key='bbUpper'
        render={(value: number) => `$${value?.toFixed(2)}`}
      />
      <Column
        title='Bullish Trend?'
        dataIndex='bullishPeriod'
        key='bullishPeriod'
        render={(value: number) => (value === 1 ? 'Yes' : 'No')}
      />
      <Column
        title='P(Increase)'
        dataIndex='buyProba'
        key='buyProba'
        render={(value: number) => `${(100 * value)?.toFixed(2)}%`}
      />
    </Table>
  );
};

export default WatchlistTable;
